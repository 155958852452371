<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-select v-model="reqType"   @change="selected" :items="reqTypes" item-text="label" item-value="id" label="Choose Complaint Manager Report"></v-select>
          </v-col>
        </v-row>


           <v-row v-if="reqType== 0">
          <v-col cols="12" sm="3" md="3">
            <DatePicker v-model="dateFrom" label="From"></DatePicker>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <DatePicker v-model="dateTo" label="To"></DatePicker>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-select v-model="report_type" :items="report_types" item-text="name" item-value="name" label="Complaint Report Type"></v-select>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-select v-model="request_type" :items="request_types" item-text="name" item-value="id" label="Request Type"></v-select>
          </v-col>
        </v-row>

          <v-row v-if="reqType== 1">
          <v-col cols="12" sm="3" md="3">
            <DatePicker v-model="dateFrom" label="From"></DatePicker>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <DatePicker v-model="dateTo" label="To"></DatePicker>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-select v-model="report_type" :items="report_types" item-text="name" item-value="name" label="Complaint Report Type"></v-select>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-select v-model="court_type" :items="court_types" item-text="name" item-value="id" label="Request Type"></v-select>
          </v-col>
        </v-row>


        <v-btn v-if="reqType!= -1" color="primary" dark class="mb-2" @click="generateReport">{{$t('View Report')}}</v-btn>
        <v-btn v-if="reqType!= -1" color="primary" dark class="mb-2" @click="closeReport">{{$t('Close Report')}}</v-btn>


      </v-card-text>
      </v-card>
       <complaintStatusReport  v-if="reqType === 0 && flag==1" :reportId="reqType" :dateFrom="dateFrom" :dateTo="dateTo" :organisation="this.$store.getters.userInfo"></complaintStatusReport>
       <ComplaintsandCourtsReport  v-if="reqType === 1 && flag==1" :reportId="reqType" :dateFrom="dateFrom" :dateTo="dateTo" :organisation="this.$store.getters.userInfo"></ComplaintsandCourtsReport>
 

    <div v-else>
      <v-progress-circular
          v-if="flag==1"
          :size="50"
          color="primary"
          indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>


<script>

import DatePicker from "../components/DatePicker";
import complaintStatusReport from "../components/report/ComplaintStatusReport";  
import ComplaintsandCourtsReport from "../components/report/ComplaintsandCourtsReport ​";    

export default {
 created () {
  },
  components: {
 DatePicker,
 complaintStatusReport,
 ComplaintsandCourtsReport
  },
  data(){
    return {
      flag:0,
      reqType:"-1",
      reqTypes: [
        {
          id:0,
          label: "Complaint Status Report"
        },
        {
          id:1,
          label: "Complaints and Courts Report",
        }
      ],
      dateFrom: new Date().toISOString().substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),
      report_types:[
        { id:'0',name:"Active Complaints as of 2020-11-03"},
        { id:'1',name:"Completed Complaints from 2015-01-01 to 2020-11-03"},
        { id:'2',name:"Recieved Complaints from 2015-01-01 to 2020-11-03"}


      ],
      report_type:"Active Complaints as of 2020-11-03",
        request_types:[
        { id:0,name:"ATI"},
        { id:1 ,name:"Privacy"}

      ],
      request_type:0,
      court_types:[
        { id:0,name:"Active Court Procedings as of 2020-11-03"},
        { id:1 ,name:"Court Procedings Advised from 2020-04-01 to 2020-11-06"},
        { id:2 ,name:"Court Procedings Decisions from 2020-04-01 to 2020-11-06"},
        { id:3 ,name:"Court Procedings Filed From from 2020-04-01 to 2020-11-06"}

      ],
      court_type:0,
  
    };
  },
  methods:{
    selected(){

    },
    generateReport(){
      this.flag = 1;
    },
    closeReport(){
      this.flag = 0;
    }
  }
};

</script>