<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col  cols="12" sm="12" md="12">

            
<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
 style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
 <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
  <td style='padding:0in 0in 0in 0in'>
  <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
   style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
   <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
    <td style='padding:0in 0in 0in 0in'>
    <div>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     width=1440 style='width:15.0in;border-collapse:collapse;mso-yfti-tbllook:
     1184;mso-padding-alt:0in 0in 0in 0in'>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
      height:30.4pt'>
      <td width=47 valign=top style='width:35.25pt;padding:0in 0in 0in 0in;
      height:30.4pt'></td>
      <td width=1440 valign=top style='width:15.0in;padding:0in 0in 0in 0in;
      height:30.4pt;min-width: 618.99mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:.25in'>
        <td width=1065 valign=top style='width:798.85pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 280.37mm'>
        <div>
        <p class=MsoNormal align=center style='text-align:center'><b><span
        style='font-size:14.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Complaint Status Report<o:p></o:p></span></b></p>
        </div>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </div>
    </td>
   </tr>
   <tr style='mso-yfti-irow:1'>
    <td style='padding:0in 0in 0in 0in'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'
     cols=5>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes'>
      <td valign=top style='padding:0in 0in 0in 0in'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       width=1440 style='width:15.0in;border-collapse:collapse;mso-yfti-tbllook:
       1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:17.9pt'>
        <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:17.9pt'></td>
        <td width=36 style='width:27.0pt;padding:0in 0in 0in 0in;height:17.9pt;
        min-width: 9.53mm'></td>
        <td width=0 style='width:.35pt;padding:0in 0in 0in 0in;height:17.9pt;
        min-width: 0.13mm'></td>
        <td width=690 style='width:517.2pt;padding:0in 0in 0in 0in;height:17.9pt;
        min-width: 182.43mm'></td>
        <td width=1440 style='width:15.0in;padding:0in 0in 0in 0in;height:17.9pt;
        min-width: 439.34mm'></td>
       </tr>
       <tr style='mso-yfti-irow:1'>
        <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in'></td>
        <td width=36 valign=top style='width:27.0pt;padding:0in 0in 0in 0in;
        min-width: 9.53mm'></td>
        <td width=690 colspan=2 valign=top style='width:517.55pt;padding:0in 0in 0in 0in;
        min-width: 182.56mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         width=690 style='width:517.55pt;border-collapse:collapse;mso-yfti-tbllook:
         1184;mso-padding-alt:0in 0in 0in 0in;min-width: 182.56mm' cols=5>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
          <td width=210 style='width:157.5pt;padding:0in 0in 0in 0in;
          min-width: 182.56mm'></td>
          <td width=134 style='width:100.5pt;padding:0in 0in 0in 0in;
          min-width: 35.45mm'></td>
          <td width=126 style='width:94.5pt;padding:0in 0in 0in 0in;min-width: 33.34mm'></td>
          <td width=124 style='width:93.0pt;padding:0in 0in 0in 0in;min-width: 32.81mm'></td>
          <td width=96 style='width:1.0in;padding:0in 0in 0in 0in;min-width: 25.40mm'></td>
         </tr>
         <tr style='mso-yfti-irow:1;height:.25in'>
          <td width=210 style='width:157.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=480 colspan=4 valign=top style='width:360.05pt;border:solid silver 1.0pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>STATUS AS OF
          2020-11-04<o:p></o:p></span></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:2;height:28.5pt'>
          <td width=210 style='width:157.5pt;border:solid silver 1.0pt;
          mso-border-alt:solid silver .25pt;background:lightsteelblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:28.5pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Type of
          Complaint<o:p></o:p></span></b></p>
          </td>
          <td width=134 valign=top style='width:100.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;background:lightsteelblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:28.5pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>New<o:p></o:p></span></b></p>
          </td>
          <td width=126 valign=top style='width:94.5pt;border:solid silver 1.0pt;
          border-left:none;mso-border-left-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;background:lightsteelblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:28.5pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>With IC for
          Action<o:p></o:p></span></b></p>
          </td>
          <td width=124 valign=top style='width:93.0pt;border:solid silver 1.0pt;
          border-left:none;mso-border-left-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;background:lightsteelblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:28.5pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>With OIC for
          Action<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border:solid silver 1.0pt;
          border-left:none;mso-border-left-alt:solid black 2.25pt;mso-border-alt:
          solid silver .25pt;mso-border-left-alt:solid black 2.25pt;background:
          lightsteelblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:28.5pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Total<o:p></o:p></span></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:3;height:.25in'>
          <td width=210 valign=top style='width:157.5pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>Exemptions/Exclusions<o:p></o:p></span></p>
          </td>
          <td width=134 valign=top style='width:100.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=126 valign=top style='width:94.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=124 valign=top style='width:93.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid black 2.25pt;
          mso-border-alt:solid silver .25pt;mso-border-left-alt:solid black 2.25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:4;height:.25in'>
          <td width=210 valign=top style='width:157.5pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>Refusal (General &amp; Misc)<o:p></o:p></span></p>
          </td>
          <td width=134 valign=top style='width:100.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>1<o:p></o:p></span></p>
          </td>
          <td width=126 valign=top style='width:94.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=124 valign=top style='width:93.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid black 2.25pt;
          mso-border-alt:solid silver .25pt;mso-border-left-alt:solid black 2.25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>1<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:5;height:.25in'>
          <td width=210 valign=top style='width:157.5pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>Fees<o:p></o:p></span></p>
          </td>
          <td width=134 valign=top style='width:100.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=126 valign=top style='width:94.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=124 valign=top style='width:93.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid black 2.25pt;
          mso-border-alt:solid silver .25pt;mso-border-left-alt:solid black 2.25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:6;height:.25in'>
          <td width=210 valign=top style='width:157.5pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>Extension<o:p></o:p></span></p>
          </td>
          <td width=134 valign=top style='width:100.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=126 valign=top style='width:94.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=124 valign=top style='width:93.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid black 2.25pt;
          mso-border-alt:solid silver .25pt;mso-border-left-alt:solid black 2.25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:7;height:.25in'>
          <td width=210 valign=top style='width:157.5pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>Other<o:p></o:p></span></p>
          </td>
          <td width=134 valign=top style='width:100.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=126 valign=top style='width:94.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=124 valign=top style='width:93.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid black 2.25pt;
          mso-border-alt:solid silver .25pt;mso-border-left-alt:solid black 2.25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:8;mso-yfti-lastrow:yes;height:.25in'>
          <td width=210 valign=top style='width:157.5pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid black 2.25pt;mso-border-alt:
          solid silver .25pt;mso-border-top-alt:solid black 2.25pt;padding:
          2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:10.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>TOTAL<o:p></o:p></span></b></p>
          </td>
          <td width=134 valign=top style='width:100.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid black 2.25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;mso-border-top-alt:solid black 2.25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>1<o:p></o:p></span></b></p>
          </td>
          <td width=126 valign=top style='width:94.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid black 2.25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;mso-border-top-alt:solid black 2.25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></b></p>
          </td>
          <td width=124 valign=top style='width:93.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid black 2.25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;mso-border-top-alt:solid black 2.25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid black 2.25pt;mso-border-left-alt:solid black 2.25pt;
          mso-border-top-alt:black 2.25pt;mso-border-left-alt:black 2.25pt;
          mso-border-bottom-alt:silver .25pt;mso-border-right-alt:silver .25pt;
          mso-border-style-alt:solid;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>1<o:p></o:p></span></b></p>
          </td>
         </tr>
        </table>
        </td>
        <td width=1440 valign=top style='width:15.0in;padding:0in 0in 0in 0in;
        min-width: 439.34mm'></td>
       </tr>
       <tr style='mso-yfti-irow:2;height:42.2pt'>
        <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:42.2pt'></td>
        <td width=1440 colspan=4 style='width:15.0in;padding:0in 0in 0in 0in;
        height:42.2pt;min-width: 631.43mm'></td>
       </tr>
       <tr style='mso-yfti-irow:3;mso-yfti-lastrow:yes'>
        <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in'></td>
        <td width=37 colspan=2 valign=top style='width:27.4pt;padding:0in 0in 0in 0in;
        min-width: 9.66mm'></td>
        <td width=1440 colspan=2 valign=top style='width:15.0in;padding:0in 0in 0in 0in;
        min-width: 621.77mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         width=1440 style='width:15.0in;border-collapse:collapse;mso-yfti-tbllook:
         1184;mso-padding-alt:0in 0in 0in 0in;min-width: 469.37mm' cols=12>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
          <td width=32 style='width:24.0pt;padding:0in 0in 0in 0in;min-width: 469.37mm'></td>
          <td width=115 style='width:86.25pt;padding:0in 0in 0in 0in;
          min-width: 30.43mm'></td>
          <td width=250 style='width:187.55pt;padding:0in 0in 0in 0in;
          min-width: 66.15mm'></td>
          <td width=110 style='width:82.5pt;padding:0in 0in 0in 0in;min-width: 29.10mm'></td>
          <td width=190 style='width:142.5pt;padding:0in 0in 0in 0in;
          min-width: 50.27mm'></td>
          <td width=241 style='width:180.75pt;padding:0in 0in 0in 0in;
          min-width: 63.76mm'></td>
          <td width=244 style='width:183.05pt;padding:0in 0in 0in 0in;
          min-width: 64.56mm'></td>
          <td width=96 style='width:1.0in;padding:0in 0in 0in 0in;min-width: 25.40mm'></td>
          <td width=96 style='width:1.0in;padding:0in 0in 0in 0in;min-width: 25.40mm'></td>
          <td width=96 style='width:1.0in;padding:0in 0in 0in 0in;min-width: 25.40mm'></td>
          <td width=208 style='width:156.0pt;padding:0in 0in 0in 0in;
          min-width: 55.03mm'></td>
          <td width=96 style='width:1.0in;padding:0in 0in 0in 0in;min-width: 25.40mm'></td>
         </tr>
         <tr style='mso-yfti-irow:1;height:.25in'>
          <td width=32 valign=top style='width:24.0pt;border:solid silver 1.0pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=1150 colspan=6 valign=top style='width:862.6pt;border:solid silver 1.0pt;
          border-left:none;mso-border-left-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Complaint Details - ATI<o:p></o:p></span></i></p>
          </td>
          <td width=496 colspan=4 valign=top style='width:372.05pt;border:solid silver 1.0pt;
          border-left:none;mso-border-left-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Last Action Started<o:p></o:p></span></i></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border:solid silver 1.0pt;
          border-left:none;mso-border-left-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>S.35/S.37 Rec'd<o:p></o:p></span></i></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:2;height:15.1pt'>
          <td width=32 valign=top style='width:24.0pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><i><span
          style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Id<o:p></o:p></span></i></p>
          </td>
          <td width=115 valign=top style='width:86.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><i><span
          style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Request<o:p></o:p></span></i></p>
          </td>
          <td width=250 valign=top style='width:187.55pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><i><span
          style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Request Summary<o:p></o:p></span></i></p>
          </td>
          <td width=110 valign=top style='width:82.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Date Received<o:p></o:p></span></i></p>
          </td>
          <td width=190 valign=top style='width:142.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Reason<o:p></o:p></span></i></p>
          </td>
          <td width=241 valign=top style='width:180.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Status<o:p></o:p></span></i></p>
          </td>
          <td width=244 valign=top style='width:183.05pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Complaint Note<o:p></o:p></span></i></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <div>
          <div>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span class=a5341><span style='font-size:8.0pt;mso-fareast-font-family:
          "Times New Roman"'>Start Date</span></span><span style='font-size:
          1.0pt;mso-fareast-font-family:"Times New Roman"'><o:p></o:p></span></p>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span class=a5361><span style='font-size:8.0pt;mso-fareast-font-family:
          "Times New Roman"'>&nbsp;</span></span><span style='font-size:1.0pt;
          mso-fareast-font-family:"Times New Roman"'><o:p></o:p></span></p>
          </div>
          </div>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Due Date<o:p></o:p></span></i></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>End Date<o:p></o:p></span></i></p>
          </td>
          <td width=208 valign=top style='width:156.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Event<o:p></o:p></span></i></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:3;mso-yfti-lastrow:yes;height:19.5pt'>
          <td width=32 valign=top style='width:24.0pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:19.5pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>1<o:p></o:p></span></p>
          </td>
          <td width=115 valign=top style='width:86.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:19.5pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>A-2017-00002<o:p></o:p></span></p>
          </td>
          <td width=250 valign=top style='width:187.55pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:19.5pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>All and any information on the monies spent on
          Autonomous Community Solutions from January 2011 to present day.<o:p></o:p></span></p>
          </td>
          <td width=110 valign=top style='width:82.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:19.5pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2017-06-01<o:p></o:p></span></p>
          </td>
          <td width=190 valign=top style='width:142.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:19.5pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>ATI - Refusal
          (General) - No Records / Incomplete Response<o:p></o:p></span></p>
          </td>
          <td width=241 valign=top style='width:180.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:19.5pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Notice of
          Complaint Received<o:p></o:p></span></p>
          </td>
          <td width=244 valign=top style='width:183.05pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:19.5pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Complaint
          received from the OIC (Rute de Sousa) re: no records found. <br>
          Documents sent to the OIC June 6, 2017<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:19.5pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2017-06-06<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:19.5pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:19.5pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=208 valign=top style='width:156.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:19.5pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Complaint
          received from the OIC (Rute de Sousa) re: no records found. <br>
          Documents sent to the OIC June 6, 2017<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:19.5pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
        </table>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </td>
   </tr>
   <tr style='mso-yfti-irow:2;mso-yfti-lastrow:yes'>
    <td style='padding:0in 0in 0in 0in'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     width=1440 style='width:15.0in;border-collapse:collapse;mso-yfti-tbllook:
     1184;mso-padding-alt:0in 0in 0in 0in' cols=5>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:11.25pt'>
      <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:11.25pt'></td>
      <td width=26 style='width:19.5pt;padding:0in 0in 0in 0in;height:11.25pt;
      min-width: 6.88mm'></td>
      <td width=192 style='width:2.0in;padding:0in 0in 0in 0in;height:11.25pt;
      min-width: 50.80mm'></td>
      <td width=824 style='width:618.1pt;padding:0in 0in 0in 0in;height:11.25pt;
      min-width: 218.02mm'></td>
      <td width=1345 style='width:1008.5pt;padding:0in 0in 0in 0in;height:11.25pt;
      min-width: 355.73mm'></td>
     </tr>
     <tr style='mso-yfti-irow:1;height:9.0pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:9.0pt'></td>
      <td width=26 valign=top style='width:19.5pt;padding:0in 0in 0in 0in;
      height:9.0pt;min-width: 6.88mm'></td>
      <td width=192 valign=top style='width:2.0in;padding:0in 0in 0in 0in;
      height:9.0pt;min-width: 50.80mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:9.0pt'>
        <td width=192 valign=top style='width:2.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:9.0pt;min-width: 49.39mm'>
        <div>
        <p class=MsoNormal><b><i><span style='font-size:8.0pt;mso-fareast-font-family:
        "Times New Roman";color:black'>2020-11-04 10:07:46 AM<o:p></o:p></span></i></b></p>
        </div>
        </td>
       </tr>
      </table>
      </td>
      <td width=824 valign=top style='width:618.1pt;padding:0in 0in 0in 0in;
      height:9.0pt;min-width: 218.02mm'></td>
      <td width=1345 valign=top style='width:1008.5pt;padding:0in 0in 0in 0in;
      height:9.0pt;min-width: 355.73mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:9.0pt'>
        <td width=192 valign=top style='width:2.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:9.0pt;min-width: 49.39mm'>
        <div>
        <p class=MsoNormal align=right style='text-align:right'><b><i><span
        style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Page 1 / 1<o:p></o:p></span></i></b></p>
        </div>
        </td>
       </tr>
      </table>
      </td>
     </tr>
     <tr style='mso-yfti-irow:2;mso-yfti-lastrow:yes;height:14.25pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:14.25pt'></td>
      <td width=1042 colspan=3 valign=top style='width:781.6pt;padding:0in 0in 0in 0in;
      height:14.25pt;min-width: 275.70mm'></td>
      <td width=1345 valign=top style='width:1008.5pt;padding:0in 0in 0in 0in;
      height:14.25pt;min-width: 355.73mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:9.0pt'>
        <td width=192 valign=top style='width:2.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:9.0pt;min-width: 49.39mm'>
        <div>
        <p class=MsoNormal align=right style='text-align:right'><b><i><span
        style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Axcess-1<o:p></o:p></span></i></b></p>
        </div>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </td>
   </tr>
  </table>
  </td>
  <td width="100%" style='width:100.0%;padding:0in 0in 0in 0in'></td>
 </tr>
 <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes'>
  <td width=0 style='width:.3pt;padding:0in 0in 0in 0in'></td>
  <td style='padding:0in 0in 0in 0in'></td>
 </tr>
</table>

<p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'><o:p>&nbsp;</o:p></span></p>


        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>

//import { mapGetters, mapState} from "vuex";
import axcessApi from "@/plugins/axcessApi";
export default {
  props:['organisation','dateFrom','dateTo','reportId'],
  created(){
    this.generateReport();
  },
  methods: {
    generateReport() {
      let data = {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        reportId: this.reportId

      }
      const response = axcessApi.postRequest("/report/tbs",data);
      response.then(output => {
        console.log(output);
      })
    },
    selected() {
    }
  }
};
</script>