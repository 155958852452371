<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col  cols="12" sm="12" md="12">


          
<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0>
 <tr>
  <td style='padding:0in 0in 0in 0in'>
  <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0>
   <tr>
    <td style='padding:0in 0in 0in 0in'>
    <div>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     width=1259 style='width:944.35pt;border-collapse:collapse'>
     <tr style='height:.25in'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:.25in'></td>
      <td width=47 valign=top style='width:35.25pt;padding:0in 0in 0in 0in;
      height:.25in;min-width: 12.44mm'></td>
      <td width=1212 valign=top style='width:909.1pt;padding:0in 0in 0in 0in;
      height:.25in;min-width: 320.67mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0>
       <tr style='height:.25in'>
        <td width=1065 valign=top style='width:798.85pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 280.37mm'>
        <p class=MsoNormal align=center style='text-align:center'><b><span
        style='font-size:14.0pt;color:black'>Complaints and Court Challenges</span></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
     <tr style='height:1.0pt'>
      <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:1.0pt'></td>
      <td width=1259 colspan=2 style='width:944.35pt;padding:0in 0in 0in 0in;
      height:1.0pt;min-width: 333.11mm'></td>
     </tr>
     <tr style='height:75.15pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:75.15pt'></td>
      <td width=47 valign=top style='width:35.25pt;padding:0in 0in 0in 0in;
      height:75.15pt;min-width: 12.44mm'></td>
      <td width=1212 valign=top style='width:909.1pt;padding:0in 0in 0in 0in;
      height:75.15pt;min-width: 320.67mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0>
       <tr style='height:71.15pt'>
        <td width=1065 valign=top style='width:798.85pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:71.15pt;min-width: 280.37mm'>
        <div>
        <p class=MsoNormal><b><span style='font-size:10.0pt;color:black'>Parameters:
        <br>
        Start Date: 2020-03-04<br>
        End Date: 2020-11-06<br>
        Run Date: 2020-11-04<br>
        Complaint Type: Active Complaints as of 2020-11-04<br>
        Court Type: Active Court Proceedings as of 2020-11-04</span></b></p>
        </div>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </div>
    </td>
   </tr>
   <tr>
    <td style='padding:0in 0in 0in 0in'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 cols=4>
     <tr>
      <td valign=top style='padding:0in 0in 0in 0in'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       width=1259 style='width:944.35pt;border-collapse:collapse'>
       <tr style='height:3.55pt'>
        <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:3.55pt'></td>
        <td width=33 style='width:24.75pt;padding:0in 0in 0in 0in;height:3.55pt;
        min-width: 8.73mm'></td>
        <td width=3 style='width:2.25pt;padding:0in 0in 0in 0in;height:3.55pt;
        min-width: 0.79mm'></td>
        <td width=1223 style='width:917.4pt;padding:0in 0in 0in 0in;height:
        3.55pt;min-width: 323.59mm'></td>
       </tr>
       <tr>
        <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in'></td>
        <td width=36 colspan=2 rowspan=2 valign=top style='width:27.0pt;
        padding:0in 0in 0in 0in;min-width: 9.53mm'></td>
        <td width=1223 valign=top style='width:917.4pt;padding:0in 0in 0in 0in;
        min-width: 323.59mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         width=1215 style='width:911.35pt;border-collapse:collapse;min-width: 321.47mm'
         cols=12>
         <tr>
          <td width=115 style='width:86.25pt;padding:0in 0in 0in 0in;
          min-width: 321.47mm'></td>
          <td width=213 style='width:159.8pt;padding:0in 0in 0in 0in;
          min-width: 56.36mm'></td>
          <td width=78 style='width:58.5pt;padding:0in 0in 0in 0in;min-width: 20.64mm'></td>
          <td width=46 style='width:34.5pt;padding:0in 0in 0in 0in;min-width: 12.17mm'></td>
          <td width=50 style='width:37.5pt;padding:0in 0in 0in 0in;min-width: 13.23mm'></td>
          <td width=70 style='width:52.5pt;padding:0in 0in 0in 0in;min-width: 18.52mm'></td>
          <td width=136 style='width:102.0pt;padding:0in 0in 0in 0in;
          min-width: 35.98mm'></td>
          <td width=81 style='width:60.75pt;padding:0in 0in 0in 0in;min-width: 21.43mm'></td>
          <td width=144 style='width:1.5in;padding:0in 0in 0in 0in;min-width: 38.10mm'></td>
          <td width=128 style='width:96.0pt;padding:0in 0in 0in 0in;min-width: 33.87mm'></td>
          <td width=77 style='width:57.75pt;padding:0in 0in 0in 0in;min-width: 20.37mm'></td>
          <td width=77 style='width:57.75pt;padding:0in 0in 0in 0in;min-width: 20.37mm'></td>
         </tr>
         <tr style='height:.25in'>
          <td width=1215 colspan=12 valign=top style='width:911.35pt;
          border:solid silver 1.0pt;border-bottom:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='color:black'>Active Complaints as of 2020-11-04 (1)</span></i></b></p>
          </td>
         </tr>
         <tr style='height:.25in'>
          <td width=115 valign=top style='width:86.25pt;border:solid silver 1.0pt;
          border-bottom:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
          <td width=213 valign=top style='width:159.8pt;border-top:solid silver 1.0pt;
          border-left:none;border-bottom:none;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border:solid silver 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'>&nbsp;</p>
          </td>
          <td width=96 colspan=2 valign=top style='width:1.0in;border:solid silver 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Days</span></i></p>
          </td>
          <td width=636 colspan=6 valign=top style='width:477.05pt;border:solid silver 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Complaint Details</span></i></p>
          </td>
          <td width=77 valign=top style='width:57.75pt;border:solid silver 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'>&nbsp;</p>
          </td>
         </tr>
         <tr style='height:15.1pt'>
          <td width=115 valign=top style='width:86.25pt;border:solid silver 1.0pt;
          border-top:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><i><span
          style='font-size:8.0pt;color:black'>Request</span></i></p>
          </td>
          <td width=213 valign=top style='width:159.8pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><i><span
          style='font-size:8.0pt;color:black'>Request Summary</span></i></p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Applicant</span></i></p>
          </td>
          <td width=46 valign=top style='width:34.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Allowed</span></i></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Taken</span></i></p>
          </td>
          <td width=70 valign=top style='width:52.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Date Received</span></i></p>
          </td>
          <td width=136 valign=top style='width:102.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Reason</span></i></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Investigator</span></i></p>
          </td>
          <td width=144 valign=top style='width:1.5in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Finding</span></i></p>
          </td>
          <td width=128 valign=top style='width:96.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Result</span></i></p>
          </td>
          <td width=77 valign=top style='width:57.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Date Closed</span></i></p>
          </td>
          <td width=77 valign=top style='width:57.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Request Status</span></i></p>
          </td>
         </tr>
         <tr style='height:.25in'>
          <td width=1138 colspan=11 valign=top style='width:853.6pt;border:
          solid silver 1.0pt;border-top:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:10.0pt;color:black'>ATI - Refusal (General) - No
          Records / Incomplete Response</span></b></p>
          </td>
          <td width=77 valign=top style='width:57.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
         </tr>
         <tr style='height:15.1pt'>
          <td width=115 valign=top style='width:86.25pt;border:solid silver 1.0pt;
          border-top:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><u><span
          style='font-size:8.0pt;font-family:"Arial",sans-serif;color:blue'><a
          href="http://hpvs332/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=20188">A-2017-00002
          / ML</a></span></u></p>
          </td>
          <td width=213 valign=top style='width:159.8pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          8.0pt;font-family:"Arial",sans-serif;color:black'>All and any
          information on the monies spent on Autonomous Community Solutions from
          January 2011 to present day.</span></p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          color:black'>Kelly salamander</span></p>
          </td>
          <td width=46 valign=top style='width:34.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          color:black'>30</span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          color:black'>20</span></p>
          </td>
          <td width=70 valign=top style='width:52.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          color:black'>2017-06-01</span></p>
          </td>
          <td width=136 valign=top style='width:102.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          color:black'>ATI - Refusal (General) - No Records / Incomplete
          Response</span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'>&nbsp;</p>
          </td>
          <td width=144 valign=top style='width:1.5in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          color:black'>ATI - Discontinued </span></p>
          </td>
          <td width=128 valign=top style='width:96.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'>&nbsp;</p>
          </td>
          <td width=77 valign=top style='width:57.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'>&nbsp;</p>
          </td>
          <td width=77 valign=top style='width:57.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          color:black'>Complete</span></p>
          </td>
         </tr>
         <tr style='height:19.5pt'>
          <td width=1138 colspan=11 valign=top style='width:853.6pt;border:
          none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:19.5pt'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:8.0pt;color:black'>Total ATI - Refusal (General) -
          No Records / Incomplete Response: 1</span></b></p>
          </td>
          <td width=77 valign=top style='width:57.75pt;border:none;padding:
          2.0pt 2.0pt 2.0pt 2.0pt;height:19.5pt'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
         </tr>
         <tr style='height:.25in'>
          <td width=115 valign=top style='width:86.25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
          <td width=213 valign=top style='width:159.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
          <td width=78 valign=top style='width:58.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
          <td width=46 valign=top style='width:34.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
          <td width=50 valign=top style='width:37.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
          <td width=70 valign=top style='width:52.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
          <td width=136 valign=top style='width:102.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
          <td width=81 valign=top style='width:60.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
          <td width=144 valign=top style='width:1.5in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
          <td width=128 valign=top style='width:96.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
          <td width=77 valign=top style='width:57.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
          <td width=77 valign=top style='width:57.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
         </tr>
         <tr style='height:.25in'>
          <td width=1138 colspan=11 valign=top style='width:853.6pt;padding:
          2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
          <td width=77 valign=top style='width:57.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
         </tr>
        </table>
        </td>
       </tr>
       <tr style='height:8.1pt'>
        <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:8.1pt'></td>
        <td width=1223 style='width:917.4pt;padding:0in 0in 0in 0in;height:
        8.1pt;min-width: 323.59mm'></td>
       </tr>
       <tr>
        <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in'></td>
        <td width=33 valign=top style='width:24.75pt;padding:0in 0in 0in 0in;
        min-width: 8.73mm'></td>
        <td width=1226 colspan=2 valign=top style='width:919.6pt;padding:0in 0in 0in 0in;
        min-width: 324.38mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         width=1218 style='width:913.6pt;border-collapse:collapse;min-width: 322.26mm'
         cols=15>
         <tr>
          <td width=118 style='width:88.5pt;padding:0in 0in 0in 0in;min-width: 322.26mm'></td>
          <td width=158 style='width:118.5pt;padding:0in 0in 0in 0in;
          min-width: 41.80mm'></td>
          <td width=71 style='width:53.25pt;padding:0in 0in 0in 0in;min-width: 18.79mm'></td>
          <td width=52 style='width:39.0pt;padding:0in 0in 0in 0in;min-width: 13.76mm'></td>
          <td width=44 style='width:33.0pt;padding:0in 0in 0in 0in;min-width: 11.64mm'></td>
          <td width=79 style='width:59.25pt;padding:0in 0in 0in 0in;min-width: 20.90mm'></td>
          <td width=81 style='width:60.75pt;padding:0in 0in 0in 0in;min-width: 21.43mm'></td>
          <td width=67 style='width:50.25pt;padding:0in 0in 0in 0in;min-width: 17.73mm'></td>
          <td width=70 style='width:52.5pt;padding:0in 0in 0in 0in;min-width: 18.52mm'></td>
          <td width=72 style='width:.75in;padding:0in 0in 0in 0in;min-width: 19.05mm'></td>
          <td width=75 style='width:56.25pt;padding:0in 0in 0in 0in;min-width: 19.84mm'></td>
          <td width=81 style='width:60.75pt;padding:0in 0in 0in 0in;min-width: 21.43mm'></td>
          <td width=96 style='width:1.0in;padding:0in 0in 0in 0in;min-width: 25.40mm'></td>
          <td width=77 style='width:57.75pt;padding:0in 0in 0in 0in;min-width: 20.37mm'></td>
          <td width=77 style='width:57.75pt;padding:0in 0in 0in 0in;min-width: 20.37mm'></td>
         </tr>
         <tr style='height:.25in'>
          <td width=1218 colspan=15 valign=top style='width:913.6pt;border:
          solid silver 1.0pt;border-bottom:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='color:black'>Active Court Proceedings as of 2020-11-04 (0)</span></i></b></p>
          </td>
         </tr>
         <tr style='height:.25in'>
          <td width=118 valign=top style='width:88.5pt;border:solid silver 1.0pt;
          border-bottom:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
          <td width=158 valign=top style='width:118.5pt;border-top:solid silver 1.0pt;
          border-left:none;border-bottom:none;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border:solid silver 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'>&nbsp;</p>
          </td>
          <td width=96 colspan=2 valign=top style='width:1.0in;border:solid silver 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Days</span></i></p>
          </td>
          <td width=698 colspan=9 valign=top style='width:523.55pt;border:solid silver 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Court Details</span></i></p>
          </td>
          <td width=77 valign=top style='width:57.75pt;border:solid silver 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'>&nbsp;</p>
          </td>
         </tr>
         <tr style='height:15.1pt'>
          <td width=118 valign=top style='width:88.5pt;border:solid silver 1.0pt;
          border-top:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><i><span
          style='font-size:8.0pt;color:black'>Request</span></i></p>
          </td>
          <td width=158 valign=top style='width:118.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><i><span
          style='font-size:8.0pt;color:black'>Request Summary</span></i></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Applicant</span></i></p>
          </td>
          <td width=52 valign=top style='width:39.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Allowed</span></i></p>
          </td>
          <td width=44 valign=top style='width:33.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Taken</span></i></p>
          </td>
          <td width=79 valign=top style='width:59.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Date Application
          Filed</span></i></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Date Advised</span></i></p>
          </td>
          <td width=67 valign=top style='width:50.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Court File Number</span></i></p>
          </td>
          <td width=70 valign=top style='width:52.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Contact File Number</span></i></p>
          </td>
          <td width=72 valign=top style='width:.75in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Section 41</span></i></p>
          </td>
          <td width=75 valign=top style='width:56.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Section 42</span></i></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Section 43</span></i></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Court Comment</span></i></p>
          </td>
          <td width=77 valign=top style='width:57.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Date of Decision</span></i></p>
          </td>
          <td width=77 valign=top style='width:57.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Request Status</span></i></p>
          </td>
         </tr>
         <tr style='height:.25in'>
          <td width=118 valign=top style='width:88.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
          <td width=158 valign=top style='width:118.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
          <td width=71 valign=top style='width:53.25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'>&nbsp;</p>
          </td>
          <td width=52 valign=top style='width:39.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'>&nbsp;</p>
          </td>
          <td width=44 valign=top style='width:33.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'>&nbsp;</p>
          </td>
          <td width=79 valign=top style='width:59.25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'>&nbsp;</p>
          </td>
          <td width=81 valign=top style='width:60.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'>&nbsp;</p>
          </td>
          <td width=67 valign=top style='width:50.25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'>&nbsp;</p>
          </td>
          <td width=70 valign=top style='width:52.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'>&nbsp;</p>
          </td>
          <td width=72 valign=top style='width:.75in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
          <td width=75 valign=top style='width:56.25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
          <td width=81 valign=top style='width:60.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
          <td width=96 valign=top style='width:1.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
          <td width=77 valign=top style='width:57.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'>&nbsp;</p>
          </td>
          <td width=77 valign=top style='width:57.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'>&nbsp;</p>
          </td>
         </tr>
         <tr style='height:.25in'>
          <td width=1218 colspan=15 valign=top style='width:913.6pt;padding:
          2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
         </tr>
        </table>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </td>
   </tr>
   <tr>
    <td style='padding:0in 0in 0in 0in'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     width=1259 style='width:944.35pt;border-collapse:collapse' cols=5>
     <tr style='height:11.25pt'>
      <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:11.25pt'></td>
      <td width=26 style='width:19.5pt;padding:0in 0in 0in 0in;height:11.25pt;
      min-width: 6.88mm'></td>
      <td width=192 style='width:2.0in;padding:0in 0in 0in 0in;height:11.25pt;
      min-width: 50.80mm'></td>
      <td width=824 style='width:618.1pt;padding:0in 0in 0in 0in;height:11.25pt;
      min-width: 218.02mm'></td>
      <td width=217 style='width:162.75pt;padding:0in 0in 0in 0in;height:11.25pt;
      min-width: 57.41mm'></td>
     </tr>
     <tr style='height:9.0pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:9.0pt'></td>
      <td width=26 valign=top style='width:19.5pt;padding:0in 0in 0in 0in;
      height:9.0pt;min-width: 6.88mm'></td>
      <td width=192 valign=top style='width:2.0in;padding:0in 0in 0in 0in;
      height:9.0pt;min-width: 50.80mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0>
       <tr style='height:9.0pt'>
        <td width=192 valign=top style='width:2.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:9.0pt;min-width: 49.39mm'>
        <div>
        <p class=MsoNormal><b><i><span style='font-size:8.0pt;color:black'>2020-11-04
        10:10:41 AM</span></i></b></p>
        </div>
        </td>
       </tr>
      </table>
      </td>
      <td width=824 valign=top style='width:618.1pt;padding:0in 0in 0in 0in;
      height:9.0pt;min-width: 218.02mm'></td>
      <td width=217 valign=top style='width:162.75pt;padding:0in 0in 0in 0in;
      height:9.0pt;min-width: 57.41mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0>
       <tr style='height:9.0pt'>
        <td width=192 valign=top style='width:2.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:9.0pt;min-width: 49.39mm'>
        <div>
        <p class=MsoNormal align=right style='text-align:right'><b><i><span
        style='font-size:8.0pt;color:black'>Page 1 / 1</span></i></b></p>
        </div>
        </td>
       </tr>
      </table>
      </td>
     </tr>
     <tr style='height:14.25pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:14.25pt'></td>
      <td width=1042 colspan=3 valign=top style='width:781.6pt;padding:0in 0in 0in 0in;
      height:14.25pt;min-width: 275.70mm'></td>
      <td width=217 valign=top style='width:162.75pt;padding:0in 0in 0in 0in;
      height:14.25pt;min-width: 57.41mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0>
       <tr style='height:9.0pt'>
        <td width=192 valign=top style='width:2.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:9.0pt;min-width: 49.39mm'>
        <div>
        <p class=MsoNormal align=right style='text-align:right'><b><i><span
        style='font-size:8.0pt;color:black'>Axcess-1</span></i></b></p>
        </div>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </td>
   </tr>
  </table>
  </td>
  <td width="100%" style='width:100.0%;padding:0in 0in 0in 0in'></td>
 </tr>
 <tr>
  <td width=0 style='width:.3pt;padding:0in 0in 0in 0in'></td>
  <td style='padding:0in 0in 0in 0in'></td>
 </tr>
</table>

<p class=MsoNormal>&nbsp;</p>



        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>

//import { mapGetters, mapState} from "vuex";
import axcessApi from "@/plugins/axcessApi";
export default {
  props:['organisation','dateFrom','dateTo','reportId'],
  created(){
    this.generateReport();
  },
  methods: {
    generateReport() {
      let data = {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        reportId: this.reportId

      }
      const response = axcessApi.postRequest("/report/tbs",data);
      response.then(output => {
        console.log(output);
      })
    },
    selected() {
    }
  }
};
</script>